<template>
  <b-container fluid>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="mr-2">New Inventory Item</h4>
      </div>
      <div class="d-flex align-items-center">
        <b-button variant="dark" class="mr-2 my-1" @click="saveAsDraft">
          Save as Draft
        </b-button>
        <b-button variant="primary" @click="submitForm"> Save </b-button>
      </div>
    </div>
    <ValidationObserver ref="formObserver">
      <b-card>
        <b-row>
          <!-- First Column -->
          <b-col cols="6">
            <!-- Product Name -->
            <ValidationProvider
              name="Product Name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Product Name">
                <b-form-input
                  v-model="productName"
                  placeholder="Enter Product Name"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Select Product Category -->
            <ValidationProvider
              name="Product Category"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Select Product Category">
                <v-select
                  v-model="productCategory"
                  :options="productCategories"
                  label="category_name"
                  :reduce="(category) => category.id"
                  placeholder="Select a Product Category"
                  class="v-select"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Selling Price -->
            <ValidationProvider
              name="Selling Price"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Selling Price">
                <b-form-input
                  v-model="sellingPrice"
                  placeholder="Selling Price"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Cost Price -->
            <ValidationProvider
              name="Cost Price"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Cost Price">
                <b-form-input
                  v-model="costPrice"
                  placeholder="Cost Price"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Quantity in Stock -->
            <ValidationProvider
              name="Quantity in Stock"
              rules="required|integer"
              v-slot="{ errors }"
            >
              <b-form-group label="Quantity in Stock">
                <b-form-input
                  v-model="quantityInStock"
                  type="number"
                  placeholder="Enter Quantity in Stock"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Warehouse Location -->
            <ValidationProvider
              name="Warehouse Location"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Warehouse Location">
                <v-select
                v-model="warehouseLocation"
                :options="branches"
                label="name"
                placeholder="Select a Warehouse Location"
                class="v-select"
                multiple
              />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Discount -->
            <b-form-group label="Discount">
              <b-row>
                <b-col cols="9">
                  <b-form-input
                    v-model="discount"
                    placeholder="Enter Discount"
                    :disabled="!addDiscount"
                  ></b-form-input>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addDiscount"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Expiry Date -->
            <b-form-group label="Expiry Date">
              <b-row>
                <b-col cols="9">
                  <flat-pickr
                    v-model="expiryDate"
                    class="form-control"
                    placeholder="Select Expiry Date"
                    :disabled="!addExpiryDate"
                  ></flat-pickr>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addExpiryDate"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <!-- Second Column -->
          <b-col cols="6">
            <!-- Short Description -->
            <b-form-group label="Short Description">
              <b-form-input
                v-model="shortDescription"
                placeholder="Enter Short Description"
              ></b-form-input>
            </b-form-group>

            <!-- Product Long Description -->
            <b-form-group label="Product Long Description">
              <b-form-textarea
                v-model="longDescription"
                placeholder="Enter Product Long Description"
              ></b-form-textarea>
            </b-form-group>

            <!-- Return Policy -->
            <b-form-group label="Return Policy">
              <b-row>
                <b-col cols="9">
                  <b-form-textarea
                    v-model="returnPolicy"
                    placeholder="Enter Return Policy"
                    :disabled="!addReturnPolicy"
                  ></b-form-textarea>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addReturnPolicy"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Date Added -->
            <b-form-group label="Date Added">
              <flat-pickr
                v-model="dateAdded"
                class="form-control"
                placeholder="Select Date and Time"
              ></flat-pickr>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-form-group label="Upload Image">
          <b-form-file
            v-model="uploadedFiles"
            multiple
            accept="image/*"
            @change="handleImageUpload"
          ></b-form-file>
        </b-form-group>
        <div class="d-flex flex-wrap">
          <div
            v-for="(image, index) in uploadedImages"
            :key="index"
            class="mr-2"
          >
            <img :src="image.url" class="img-thumbnail" alt="Uploaded Image" />
          </div>
        </div>
      </b-card>
    </ValidationObserver>
  </b-container>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, numeric, integer } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
  },

  data() {
    return {
      productName: "",
      productCategory: null, // Initialize to null
      productCategories: [], // Populate this with your categories
      branches: [],
      sellingPrice: "",
      costPrice: "",
      quantityInStock: "",
      warehouseLocation: "",
      discount: "",
      addDiscount: false,
      expiryDate: null,
      addExpiryDate: false,
      shortDescription: "",
      longDescription: "",
      returnPolicy: "",
      addReturnPolicy: false,
      dateAdded: null,
      uploadedFiles: [], // New data property for file input
      uploadedImages: [],
      required,
      numeric,
      integer,
    };
  },

  created() {
    this.fetchProductCategories();
    this.fetchBranches();
    this.loadDraft();
  },

  methods: {
    async fetchProductCategories() {
      try {
        const response = await this.$http.get(
          "inventory/admin/get_product_categories"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.productCategories = response.data.data;
        } else {
          this.productCategories = [];
        }
      } catch (error) {
        console.error("Error fetching product categories:", error);
        this.productCategories = [];
      }
    },

    async fetchBranches() {
      try {
        const response = await this.$http.get("inventory/admin/get_all_warehouses");
        if (response.status === 200 && response.data.data?.length > 0) {
          this.branches = response.data.data;
        } else {
          this.branches = [];
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        this.branches = [];
      }
    },

    loadDraft() {
      const draft = localStorage.getItem("addProductDraft");
      if (draft) {
        const parsedDraft = JSON.parse(draft);
        this.productName = parsedDraft.productName;
        this.productCategory = parsedDraft.productCategory;
        this.sellingPrice = parsedDraft.sellingPrice;
        this.costPrice = parsedDraft.costPrice;
        this.quantityInStock = parsedDraft.quantityInStock;
        this.warehouseLocation = parsedDraft.warehouseLocation;
        this.discount = parsedDraft.discount;
        this.addDiscount = parsedDraft.addDiscount;
        this.expiryDate = parsedDraft.expiryDate;
        this.addExpiryDate = parsedDraft.addExpiryDate;
        this.shortDescription = parsedDraft.shortDescription;
        this.longDescription = parsedDraft.longDescription;
        this.returnPolicy = parsedDraft.returnPolicy;
        this.addReturnPolicy = parsedDraft.addReturnPolicy;
        this.dateAdded = parsedDraft.dateAdded;
        this.uploadedImages = parsedDraft.uploadedImages;
      }
    },

    async submitForm() {
      const isValid = await this.$refs.formObserver.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append("product_category_id", this.productCategory);
      formData.append("product_name", this.productName);
      formData.append("quantity", this.quantityInStock);
      formData.append("short_description", this.shortDescription);
      formData.append("long_description", this.longDescription);
      formData.append("selling_price", this.sellingPrice);
      formData.append("cost_price", this.costPrice);
      formData.append("date_added", this.dateAdded);

      if (this.addDiscount) {
        formData.append("discount", this.discount);
      }

      if (this.addExpiryDate) {
        formData.append("expiry_date", this.expiryDate);
      }

      if (this.addReturnPolicy) {
        formData.append("return_policy", this.returnPolicy);
      }

      this.uploadedImages.forEach((image, index) => {
        formData.append(`product_images[${index}]`, image.file);
      });

      try {
        const response = await this.$http.post(
          "inventory/admin/products",
          formData
        );
        console.log("Product saved successfully:", response.data);
      } catch (error) {
        console.error("Error saving product:", error);
      }
    },

    handleImageUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({ file: files[i], url: e.target.result });
        };
        reader.readAsDataURL(files[i]);
      }
    },

    saveAsDraft() {
      const draft = {
        productName: this.productName,
        productCategory: this.productCategory,
        sellingPrice: this.sellingPrice,
        costPrice: this.costPrice,
        quantityInStock: this.quantityInStock,
        warehouseLocation: this.warehouseLocation,
        discount: this.discount,
        addDiscount: this.addDiscount,
        expiryDate: this.expiryDate,
        addExpiryDate: this.addExpiryDate,
        shortDescription: this.shortDescription,
        longDescription: this.longDescription,
        returnPolicy: this.returnPolicy,
        addReturnPolicy: this.addReturnPolicy,
        dateAdded: this.dateAdded,
        uploadedImages: this.uploadedImages,
      };

      localStorage.setItem("addProductDraft", JSON.stringify(draft));
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Draft Saved",
          icon: "CheckIcon",
          variant: "success",
          text: "Your draft has been saved successfully.",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>